
import { Howl, Howler } from 'howler';

export default class SoundSprite{
    constructor(p5,sound){
        this.soundPlayed = false;
        this.pos = p5.createVector(0, 0);
        this.minDist=0;
        this.maxDist=900000;
        this.volume=1;
        this.loop=false;
        if (sound)this.setSound(sound);
        this.paused=false;
        this.positional=true;
        this.radius=1;
        this.listeners={};
        this.distanceFun=(d)=>d/10;
    }

    setMaxDistance(maxDist){
        this.maxDist=maxDist;
    }

    setMinDistance(minDist){
        this.minDist=minDist;
    }

    addEventListener(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    removeEventListener(event, callback) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter(l => l != callback);
        }
    }
    _callListeners(event, ...args) {
        if (this.listeners[event]) {
            for (const l of this.listeners[event]) {
                l(...args);
            }
        }
    }


    setDistanceFunction(distanceFun){
        this.distanceFun=distanceFun;
    }

    setRadius(radius){
        this.radius=radius;
    }

    setPositional(positional){
        this.positional=positional;
    }

    setLoop(loop){
        this.loop=loop;
    }

    setSound(s) {
        this.sound=s;
        // this.soundPlayed = false;

    }

    setVolume(volume) {
        this.volume=volume;
    }

    setPosition(pos) {
        this.pos = pos;
    }

    async pause(tpf){
        if (!this.sound) return;
        if (!this.soundInstance) return;
        if (this.soundInstance.playing()) {
            this.soundInstance.pause();
            this.playing=false;
        }
    }
    async play(tpf, listenerPos) {
        if(!this.sound)return;
        
        const volume=typeof this.volume=="function"?this.volume(this.time):this.volume;
        const pos = typeof this.pos == "function" ? this.pos(this.time) : this.pos;
        const loop=typeof this.loop=="function"?this.loop(this.time):this.loop;
        const positional=typeof this.positional=="function"?this.positional(this.time):this.positional;
       
        const radius=typeof this.radius=="function"?this.radius(this.time):this.radius;
        if (!this.soundInstance) {
            await new Promise((res,rej)=>{
                this.soundInstance = new Howl({
                    src: [this.sound],
                    volume: this.volume,
                    loop: this.loop,
                    onend: () => {
                        this._callListeners("end");
                    },
                    onload: () => {
                        this.soundInstance.play();
                        
                    },
                    onplay: () => {
                        res();
                    }
                });
            });
            
        }
        const currentVolume = this.soundInstance.volume();
        const currentPos = this.soundInstance.pos();
        // if (!this.soundPlayed) {
        //     if (this.sound) {
        //         this.sound.play();
        //     }
        //     this.soundPlayed = true;
        // }
        // is looping 
        if (this.soundInstance.loop()!=loop){
            this.soundInstance.loop(loop);
        }

        if (positional && listenerPos){
            const d =  listenerPos.dist(pos) ;

            // fake panning
            let posRelativeToListener = listenerPos.copy();
            posRelativeToListener.sub(pos);

            let distanceFromListener = posRelativeToListener.mag();
            if (distanceFromListener < radius) {
                distanceFromListener = 0;
            } else {
                distanceFromListener -= radius;
            }
            distanceFromListener=this.distanceFun(distanceFromListener);
            posRelativeToListener.setMag(distanceFromListener);
           
            if (!currentPos||currentPos[0] != posRelativeToListener.x || currentPos[1] != posRelativeToListener.y){
                this.soundInstance.pos(posRelativeToListener.x, posRelativeToListener.y, 0);
            }               
            
            if(d<this.minDist){
                if(currentVolume>0){
                    this.soundInstance.volume(0);
                }            
            }else if(d>this.maxDist){
                if(currentVolume>0){
                    this.soundInstance.volume(0);
                }
            }else{          
                if(currentVolume!=volume){
                    this.soundInstance.volume(volume);
                }
            }
        }else{
            if (currentVolume != volume) {
                this.soundInstance.volume(volume);
            }
        }
        
        // if not playing play
        if(!this.paused){
            if (!this.soundInstance.playing()&&!this.playing){
                this.soundInstance.play();
                this.playing=true;
            }
        }else{
            if (this.soundInstance.playing()&&this.playing){
                this.soundInstance.pause();
                this.playing=false;
            }
        }
    }
}