
import Matter from 'matter-js';
import p5js from 'p5';
import NostrConnection from './nostr/NostrConnection.js';
import PositionBuffer from './PositionBuffer.js';
import ExplosionSprite from './sprites/ExplosionSprite.js';
import SmokeSprite from './sprites/SmokeSprite.js';
import BackgroundMusicSprite from './sounds/BackgroundMusicSprite.js';
import ExplosionSoundSprite from './sounds/ExplosionSoundSprite.js';
import FarawayExplosionSoundSprite from './sounds/FarawayExplosionSoundSprite.js';
import CannoballSprite from './sprites/CannonballSprite.js';
let p5;
const ENABLE_PHYSICS_DEBUG=false;
const CONTEXT={
    physicsDeltaCounter:0,
    players:{}   ,
    sprites:[],
    soundSprites:[]
};


function getContext(){
    if (!CONTEXT.physicsEngine){
        CONTEXT.physicsEngine = Matter.Engine.create({ gravity: { x: 0, y: 0 } });
    }

    if (!CONTEXT.terrainGeneratorShader){
        CONTEXT.terrainGeneratorShader = p5.loadShader("./mapgen.vert", "./mapgen.frag");
    }

    if (!CONTEXT.terrainShader){
        CONTEXT.terrainShader = p5.loadShader("./map.vert", "./map.frag");
    }

    if (!CONTEXT.layer0){
        CONTEXT.layer0 =  p5.loadImage("./tiles/Texture/TX Tileset Grass.png");
    }

    if(!CONTEXT.layer1){
        CONTEXT.layer1 = p5.loadImage("./granite.png");
    }

    if(!CONTEXT.tank){
        CONTEXT.tank = p5.loadImage("./tank.webp");
    }

    if (!CONTEXT.terrainPhysicCellSize){
        CONTEXT.terrainPhysicCellSize = 4;
    }

 
    if(!CONTEXT.terrainMapRes){
        CONTEXT.terrainMapRes=512;
    }
    if (!CONTEXT.terrainSize) {
        CONTEXT.terrainSize = p5.createVector(CONTEXT.terrainMapRes, CONTEXT.terrainMapRes);
    }

    if(!CONTEXT.terrainMaskRes){
        CONTEXT.terrainMaskRes=512;
    }
    // if(!CONTEXT.terrainModifyShader){
    //     CONTEXT.terrainModifyShader = p5.loadShader("./mapedit.vert", "./mapedit.frag");
    // }
   
    if(!CONTEXT.brushes){
        CONTEXT.brushes={};
        CONTEXT.brushes["burnt"]=p5.loadImage("./brushes/burnt.webp");
        CONTEXT.brushes["burntsoft"] = p5.loadImage("./brushes/burntsoft.webp");
        CONTEXT.brushes["skid"] = p5.loadImage("./brushes/skid.webp");
    }

    if(!CONTEXT.defaultFont){
        CONTEXT.defaultFont=p5.loadFont("./fonts/Roboto-Regular.ttf");
    }
    return CONTEXT;
}


function draw2D(fb, drawFun = (p5, width, height) => { }) {
    p5.push();
    const width = fb.width;
    const height = fb.height;
    fb.begin();
    p5.camera(0, 0, -1, 0, 0, 0, 0, 1, 0);
    p5.ortho(width / 2, -width / 2, -height / 2, height / 2, 0, 1);
    p5.translate(-width / 2, -height / 2, 0);
    drawFun(p5, width, height);
    fb.end();
    p5.pop();
}


// generate terrain (once)
async function generateTerrain(){
    const context=getContext();
    const cellSize = context.terrainPhysicCellSize;

    if (!context.generatedTerrainMap){
        context.generatedTerrainMap = p5.createFramebuffer({
            width: context.terrainMapRes,
            height: context.terrainMapRes
        });
        p5.push();
        context.generatedTerrainMap.begin();
        p5.clear();
        p5.shader(context.terrainGeneratorShader);
        p5.rect(0, 0, 1, 1);
        context.generatedTerrainMap.end();
        p5.pop();       
    }
    if(!context.terrainMask){
        context.terrainMask = p5.createFramebuffer({
            width: context.terrainMaskRes,
            height: context.terrainMaskRes
        });
        p5.push();
        context.terrainMask.begin();
        p5.clear();
        context.terrainMask.end();
        p5.pop();       
    }
    if (!context.terrainPhysics) {
        const w=context.generatedTerrainMap.width;
        const h=context.generatedTerrainMap.height;
        context.terrainPhysics = [];
        for (let x = 0; x < w; x += cellSize) {
            const xi = Math.floor(x / cellSize);
            context.terrainPhysics[xi] = [];
            for (let y = 0; y < h; y += cellSize) {
                const yi = Math.floor(y / cellSize);
                const cell = Matter.Bodies.circle(x, y, cellSize / 2, { isStatic: true });
                cell.attached = false
                cell.size = cellSize;
                cell.position=p5.createVector(x,y);
                cell.refresh = async () => {
                    const xt = x;
                    const yt = y;
                    const mask = context.terrainMask.get((xt / w)*context.terrainMask.width, (yt / h)*context.terrainMask.height);
                    const color = context.generatedTerrainMap.get(xt, yt);
                    const density = (color[0] / 255.0)*(1.0-(mask[0]/255.0));
                    if (density < 0.4) {
                        if (cell.attached) {
                            Matter.Composite.remove(context.physicsEngine.world, cell);
                            cell.attached = false;
                        }
                    } else {
                        if (!cell.attached) {
                            Matter.Composite.add(context.physicsEngine.world, cell);
                            cell.attached = true;
                        }
                    }
                }
                context.terrainPhysics[xi][yi] = cell;
                await cell.refresh();
            }
        }
    }
}





async function delPlayer(peer){
    const context=getContext();
    const peerId=typeof peer=="string"?peer:peer.id;
    const player = context.players[peerId];
    if(player){
        console.info("Peer",peerId,"disconnected");
        Matter.World.remove(context.physicsEngine.world,player.physics);
        delete context.players[peerId];
    }
}

async function getPlayer(peer, create=true) {
    const context = getContext();
    const peerId=typeof peer=="string"?peer:peer.id;
    if(!context.players)context.players={};
    if (context.players[peerId]) {
        return context.players[peerId];
    }
    if(typeof peer=="string"||!create)return undefined;
    const isLocal = peerId == await context.conn.getLocalPeerId();
    
    console.info("Peer",peer,"local",isLocal,"connected!");
    const player = {
        peer:peer,
        isLocal: isLocal,
        id: peerId,
        speed: 5,
        shotCoolDown:1,
        currentShotCooldown:0,
        team: Math.random() * 360,
        lastPosPacket: undefined,
        positionSamples: new PositionBuffer(64),
        healthSamples:{
            lastTs:undefined,
            health:100,
            get(){
                return this.health;
            },
            add(hp,ts){
                if(!this.lastTs||ts>this.lastTs){
                    this.health=hp;
                    this.lastTs=ts;
                }                
            }
        },
        physics: Matter.Bodies.circle(0, 0, 5, { isStatic: false, gravityScale: 0, density: 1 }),
        bullets:[],
        bulletType:0,
        bulletSpeed:2,
        health:0,
        _tmpPos:p5.createVector(0,0),
        listeners:[],
        addListener(l){
            this.listeners.push(l);
        },
        _fireEvent(event,args){
            for(const l of this.listeners){
                l(event,args);
            }
        },
        setPos(x, y) {
            Matter.Body.setPosition(this.physics, { x, y });
            this._fireEvent("warp", [this, {x, y}]);
        },
        setRotation(rot) {
            Matter.Body.setAngle(this.physics, rot);
            this._fireEvent("rotate", [this, rot]);
        },
        getRotation() {
            return this.physics.angle;
        },
        move(vector_direction, tpf) {
            const angle = this.getRotation();
            const rotatedDirection = p5.createVector(vector_direction.x, vector_direction.y).rotate(angle + Math.PI / 2);
            const force = rotatedDirection.mult(this.speed * tpf);
            Matter.Body.applyForce(this.physics, this.physics.position, force);
            this._fireEvent("move", [this,vector_direction,tpf]);
        },
        getPos() {
            return this._tmpPos.set(this.physics.position.x, this.physics.position.y);
        },
        shot(target, meta ) {
            this.bullets.push({from:this.getPos().copy(),pos:this.getPos().copy(),target:target,i:0, meta:meta});
        },
        damage(amount, fromPlayerId){
            let isAlive=this.health>0;
            if(!isAlive)return;
            this.health-=amount;
            this._fireEvent("damage", [this, fromPlayerId, amount]);
            if(this.health<=0){
                this.health=0;
                this._fireEvent("dead",[this,fromPlayerId]);
            }
        },
        isDead(){
            return this.health<=0;
        },
        respawn(){
            const context=getContext();
            this.health=100;
            this.dead=false;
            // find a random position that is not too close to any other player or occupied cell
            let pos;
            let found=false;
            while(!found){
                pos=p5.createVector(Math.random()*context.terrainSize.x,Math.random()*context.terrainSize.y);
                found=true;
                for(const otherPlayer of Object.values(context.players)){
                    if(otherPlayer==this)continue;
                    if(otherPlayer.getPos().dist(pos)<10){
                        found=false;
                        break;
                    }
                }
                for (let x = 0; x < context.terrainPhysics.length; x++) {
                    for (let y = 0; y < context.terrainPhysics[0].length; y++) {
                        const cell = context.terrainPhysics[x][y];
                        if (!cell.attached) continue;
                        if (cell.position.dist(pos) < cell.size / 2) {
                            found=false;
                            break;
                        }
                    }
                }
            }
            this.setPos(pos.x,pos.y);
            this._fireEvent("respawn", [this]);
            console.log("Respawned",this.getPos());
        },
        setHealth(h){
            this.health=h;
            this._fireEvent("health", [this, h]);
        },
        heal(amount, fromPlayerId){
            this.health+=amount;
            this._fireEvent("heal", [this, fromPlayerId, amount]);

        },
        updateBullets(callback,tpf){
            for(let i=0;i<this.bullets.length;i++){
                const from=this.bullets[i].from;
                const to=this.bullets[i].target;
                let j=this.bullets[i].i;
                j+=this.bulletSpeed*tpf;
                if(j>1){
                    j=1;
                }
                this.bullets[i].i=j;
                const pos=from.copy().lerp(to,j);
                this.bullets[i].pos=pos;

                const reachedTarget=j==1;
                if(!callback(this.bullets[i],reachedTarget)){
                    this.bullets.splice(i,1);
                    i--;
                }
            }

        }   
    };
    player.physics.friction = 0.4;
    player.physics.frictionAir = 0.4;
    player.physics.restitution = 0.2;
    Matter.World.add(context.physicsEngine.world, player.physics);
    context.players[peerId] = player;
    return player;
}


async function playSound(soundSprite, pos, volume, loop, cache = true) {
    const context = getContext();
    const sprites = context.soundSprites;

    if(pos)soundSprite.setPosition(pos);
    if (volume)soundSprite.setVolume(volume);
    if(loop!==undefined)soundSprite.setLoop(loop);
    soundSprite.addEventListener("end", () => {
        sprites.splice(sprites.indexOf(soundSprite), 1);
    });
    sprites.push(soundSprite);
    console.log("Playing sound",soundSprite);

    return soundSprite;
}

function spawnParticle(sprite, pos, rot, size, cache=true){
    const context=getContext();
    
    const sprites=context.sprites;
    sprite.addEventListener("end",()=>{
        sprites.splice(sprites.indexOf(sprite),1);
    });
    if(pos)sprite.setPosition(pos);
    if(rot)sprite.setRotation(rot);
    if(size)sprite.setScale(size);
    sprite.setPermanentCache(cache);

    
    sprites.push(sprite);
    return sprite;
  
}


async function sendUpdatePackets(){
    try{
        const context=getContext();
        const localPeerId=await context.conn.getLocalPeerId();
        const player=context.players[localPeerId];
        if(player){
            const pos=player.getPos();
            const rot=player.getRotation();
            await sendPacket({ t: "u", p: { x: pos.x, y: pos.y }, r: rot, ts: Date.now(), h: player.health });
        }
    }catch(e){
        console.log(e);
    }
    setTimeout(sendUpdatePackets,1000/30);
}

async function sendPacket(packet,isTemp=false, important=false){
    const context = getContext();
    const localPeerId = await context.conn.getLocalPeerId();
    const player = context.players[localPeerId];
    if (player) {
        // console.log("Sending packet",packet);
        const payload = packet;
        if (!important){
            return context.conn.sendEvent(payload, isTemp?2000:0);        
        }else{
            return context.conn.sendDataEvent(payload, isTemp?2000:0);
        }
    }else{
        // console.log("Player not found",localPeerId);
    
    }
   
}

async function onPacket(peer,packet,isBackLog){
    const player =peer?await getPlayer(peer):undefined;
    if (player){
        if (packet.t == "u") {
            if (player.isLocal) {
                // console.log("Player is local")
                return;
            }
            // console.log("Received update packet",packet.p,packet.r)
            // player.setPos(packet.p.x,packet.p.y);
            player.healthSamples.add(packet.h,packet.ts);
            player.positionSamples.pushSample(packet.p.x, packet.p.y, packet.r, packet.ts + 200);
        } else if (packet.t == "s") {
            if (Date.now() - packet.ts > 1000) return;
            player.shot(p5.createVector(packet.p.x, packet.p.y));
        }

    }
    if (packet.t == "td") {
        damageTerrain(p5.createVector(packet.p[0], packet.p[1]), true);
    }
}

async function init() {
    console.info("Initializing...");
    const context = getContext();

    await generateTerrain();

    console.info("Initialize nostr connection");
    context.conn = new NostrConnection("test2",false);
   
    context.conn.addPeerEventListener(async (peer, gc) => {
        // console.log("Peer event",peer,gc);
        if(peer.timedout||peer.kicked){
            delPlayer(peer);
        }else{
            const player=await getPlayer(peer, true);
        }
    });
    context.conn.addEventListener(async (event) => {
        // console.log("Event",event)
        const peer=event.peer;
        const isBackLog=event.isBacklog;
        const payload=event.payload;
        onPacket(peer,payload,isBackLog);
    });
    context.conn.addOrphanEventListener(async (event) => {
        const isBackLog = event.isBacklog;
        const payload = event.payload;
        onPacket(undefined, payload, isBackLog);
    });
    console.info("Start");
    await context.conn.start();
    // await context.conn.waitForPeers(1);
    console.info("Connect");
    await context.conn.connect();

    // const localPeerId=context.conn.getLocalPeerId();
    // const player = await createPlayer(localPeerId, context.physicsEngine);
    // context.players[player.id] = player;
    console.info("Start data loop");
    await sendUpdatePackets();


    // start music

    playSound(new BackgroundMusicSprite(p5));
}

async function actionMove(player, moveDir,tpf ){
    player.move(moveDir, tpf);
   

}

async function actionShot(player,dir,  tpf){
    if(player.currentShotCooldown>0)  return;
    
    player.currentShotCooldown=player.shotCoolDown;

    const playerPos=player.getPos();
    const target = p5.createVector(playerPos.x, playerPos.y).add(dir.copy().mult(100));

    // player.shot(target);
    sendPacket({ t: "s", p: target, ts: Date.now() }, true);
}


function damageTerrain(pos, remote=false){
    const context = getContext();


    let x = pos.x;
    let y = pos.y;
    let w = 20;
    let h = 20;

    x /= context.terrainSize.x;
    y /= context.terrainSize.y;
    x *= context.terrainMask.width;
    y *= context.terrainMask.height;

    w /= context.terrainSize.x;
    h /= context.terrainSize.y;
    w *= context.terrainMask.width;
    h *= context.terrainMask.height;

    draw2D(context.terrainMask, (p5, maskW, maskH) => {
        const tx = context.brushes["burntsoft"];
        p5.blendMode(p5.BLEND);
        p5.noStroke();
        p5.tint(255, 0, 0);
        p5.texture(tx);
        p5.rect(x - w / 2, y - h / 2, w, h);
    });


    

    if(remote){
        console.log("Remote damage",pos.x,pos.y,w,h)
        // update all cells inside the rect
        for (let xi = 0; xi < context.terrainPhysics.length; xi++) {
            for (let yi = 0; yi < context.terrainPhysics[0].length; yi++) {
                const cell = context.terrainPhysics[xi][yi];
                if (!cell.attached) continue;
                const cellSize = context.terrainPhysicCellSize;
                const explosionSize = Math.max(w, h);
                if (cell.position.dist(p5.createVector(pos.x, pos.y)) < explosionSize + cellSize) {
                    cell.refresh();
                }
            }
        }
    }else{
        sendPacket({ t: "td", p: [pos.x, pos.y], ts: Date.now() }, false, true);
    }
}

// Rendering logic, called as many times as needed
// No guarantee of fixed time step
// Should be used for rendering only
// NOTE: NO GAME LOGIC HERE
function render(tpf){

    

    const context=getContext();
   

    // return;
// 
    p5.push();

    // set camera
    const fov = p5.PI / 3.0;
    const aspect = p5.width / p5.height;
    const near = 0.1;
    const far = 1000;
    let zoom = 90;

    p5.perspective(fov, aspect, near, far);
    p5.blendMode(p5.BLEND);
    p5.noStroke();

    { // update camera
        for (const player of Object.values(context.players)) {
            if (player.isLocal) {
                const playerWPos = player.getPos();
                p5.camera(
                    playerWPos.x, playerWPos.y, zoom,
                    playerWPos.x, playerWPos.y, 0,
                    0, 1, 0
                );       

                { // draw skid mark
                    let x = player.getPos().x;
                    let y = player.getPos().y
                    let w = 2;
                    let h = 2;

                    x /= context.terrainSize.x;
                    y /= context.terrainSize.y;
                    x *= context.terrainMask.width;
                    y *= context.terrainMask.height;

                    w /= context.terrainSize.x;
                    h /= context.terrainSize.y;
                    w *= context.terrainMask.width;
                    h *= context.terrainMask.height;
                    let rotation=player.getRotation();
                    draw2D(context.terrainMask, (p5, maskW, maskH) => {
                        const tx = context.brushes["burntsoft"];
                        p5.blendMode(p5.ADD);
                        p5.noStroke();
                        p5.tint(0, 125, 0);
                        p5.texture(tx);
                        // draw two rect one at the right of the player and one at the left
                        const rectWidth = w;
                        const rectHeight = h;
                        const distanceFromPlayer = 2; // Adjust this value as needed


                        // Apply player's rotation
                        p5.push(); // Save current drawing state
                        p5.translate(x, y); // Move origin to player's position
                        p5.rotate(rotation+p5.PI/2); // Rotate drawing context by player's rotation

                        // Rectangle to the left of the player
                        p5.rect(-distanceFromPlayer - rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight);

                        // Rectangle to the right of the player
                        p5.rect(distanceFromPlayer - rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight);

                        p5.pop(); // Restore previous drawing state
                    });
                }

                break;                    
            }
        }   
    }


    { // draw terrain
        const generatedTerrain = context.generatedTerrainMap;
        if (generatedTerrain) {

            // const cellSize = 4;
            // const w = generatedTerrain.width;
            // const h = generatedTerrain.height;

            p5.push();
            p5.textureWrap(p5.REPEAT);
            context.terrainShader.setUniform("Terrain", generatedTerrain);
            context.terrainShader.setUniform("GroundTexture", context.layer0);
            context.terrainShader.setUniform("GroundTextureRes", 16.0);
            context.terrainShader.setUniform("MountainTexture", context.layer1);
            context.terrainShader.setUniform("Mask", context.terrainMask);
            p5.shader(context.terrainShader);
            p5.translate(0, 0, -0.1);
            p5.fill(0, 255, 0);
            p5.rect(0, 0, context.terrainSize.x, context.terrainSize.y);
            p5.pop();
        }
    }

    { // draw players
        for (const player of Object.values(context.players)) {
            p5.push();
            const playerWPos = player.getPos();
            const playerWRot = player.getRotation();
            p5.translate(playerWPos.x, playerWPos.y);
            p5.rotate(playerWRot + Math.PI / 2);
            p5.texture(context.tank);
            p5.rect(-5, -5, 10, 10);
            // draw health bar as an arc (only stroke) around the player
            p5.noFill();
            // stroke width
            p5.strokeWeight(4);

            let playerHealth=player.health;
            
            const barToBarDistance=4;
            for (let i = 0; i < Math.floor(playerHealth / 100); i++) {
                p5.colorMode(p5.HSB, 360, 100, 100, 100); // Switch to HSB color mode
                let hue = p5.map(i, 0, 4, 120, 240); // Map i to a hue value between green (120) and blue (240)
                p5.stroke(hue, 100, 100, 40); // Set the stroke color

                const barValue = Math.min(playerHealth - i * 100,100);
                const d = (i * barToBarDistance);
                p5.arc(0, 0, 15 + d, 15 + d, -p5.PI / 2, -p5.PI / 2 + p5.TWO_PI * (barValue / 100),p5.OPEN, 40);
            }

            





            p5.pop();
        }   
    }

    { // draw sprites
        for (const sprite of context.sprites) {
            sprite.draw(tpf);
        }
    }

    {//debug
        if (ENABLE_PHYSICS_DEBUG) {
            for (let x = 0; x < context.terrainPhysics.length; x++) {
                for (let y = 0; y < context.terrainPhysics[0].length; y++) {
                    const cell = context.terrainPhysics[x][y];
                    if (!cell.attached) continue;
                    p5.push();
                    const size = cell.size / 2;
                    p5.translate(cell.position.x, cell.position.y);
                    p5.fill(255, 0, 0);
                    p5.triangle(-size, -size, size, -size, 0, size);
                    p5.pop();
                }
            }
        }

        
    }


  
    { // ui elements
        p5.pop();
        p5.push();
        p5.blendMode(p5.BLEND);
        p5.fill(255,255,255,100);
        p5.textSize(16);
        p5.textFont(context.defaultFont);
        const fps = Math.floor(p5.frameRate());
        if(!context.displayFPS)context.displayFPS=fps;
        else{
            context.displayFPS=context.displayFPS*0.9+fps*0.1;
        }
        p5.translate(-p5.width/2,-p5.height/2);
        p5.text(context.displayFPS.toFixed(0), 30, 30);
        p5.pop();
    }
    


}

// Game logic
// This is guaranteed to run once every 1000/60 ms
// NB. time is calculated after the end of the previous call
// should use tpf for interpolation
// Do not use this to directly render on screen stuff
async function update(tpf){
    const context = getContext();
    if(!context.initialized){
        console.log("Initialize...");
        await init();
        context.initialized=true;
    }

    // { // update terrain

    //     if (context.terrainActions) { // apply terrain actions
    //         p5.push();
    //         p5.noStroke();



    //         for (const action of context.terrainActions) {
    //             const [actionName, ...args] = action;

    //             if (actionName == "damage") {
    //                 const [wx, wy] = args;
    //                 let x=wx;
    //                 let y=wy;
    //                 let w = 20;
    //                 let h = 20;

    //                 x /= context.terrainSize.x;
    //                 y /= context.terrainSize.y;
    //                 x *= context.terrainMask.width;
    //                 y *= context.terrainMask.height;

    //                 w /= context.terrainSize.x;
    //                 h /= context.terrainSize.y;
    //                 w *= context.terrainMask.width;
    //                 h *= context.terrainMask.height;

    //                 draw2D(context.terrainMask, (p5, maskW, maskH) => {
    //                     // p5.fill(255, 0, 0);
    //                     const tx = context.brushes["burntsoft"];
    //                     p5.blendMode(p5.BLEND);
    //                     p5.texture(tx);
    //                     p5.rect(x - w / 2, y - h / 2, w, h);
    //                 });
    //                 // update all cells inside the rect
    //                 for (let xi = 0; xi < context.terrainPhysics.length; xi++) {
    //                     for (let yi = 0; yi < context.terrainPhysics[0].length; yi++) {
    //                         const cell = context.terrainPhysics[xi][yi];
    //                         if (!cell.attached) continue;
    //                         const cellSize=context.terrainPhysicCellSize;
    //                         const explosionSize=Math.max(w,h);
    //                         if (cell.position.dist(p5.createVector(wx, wy)) < explosionSize+cellSize) {
    //                             cell.refresh();
    //                         }
    //                     }
    //                 }
                    

    //             }

    //         }

    //         p5.pop();


    //         context.terrainActions.length = 0;

    //     }
    // }


    { // fixed time step physics
        context.physicsDeltaCounter+=tpf;
        const physicsEngineRate=1./60.;
        if (context.physicsDeltaCounter>physicsEngineRate){
            const n = Math.floor(context.physicsDeltaCounter/physicsEngineRate);
            for(let i=0;i<n;i++){
                Matter.Engine.update(context.physicsEngine,Math.floor(physicsEngineRate*1000), 1);
            }
            context.physicsDeltaCounter-=n*physicsEngineRate;
        }
    }


    // update players
    let localPlayer;
    for(const player of Object.values(context.players)){        
        // keep player in the middle of screen by centering camera
        if(player.isLocal){         
            localPlayer=player;  
            /* check if player is out of bounds and respawn */
            const pos=player.getPos();
            if(pos.x<0||pos.x>context.terrainSize.x||pos.y<0||pos.y>context.terrainSize.y){
                player.damage(100,undefined);
            }
            
            if(player.isDead()){
                player.respawn();
            }
            // set dir to face cursor
            const mousePos=p5.createVector(p5.mouseX,p5.mouseY);
            const playerPos=p5.createVector(p5.width/2,p5.height/2);
            const dir=mousePos.copy().sub(playerPos);
            dir.normalize();
            const angle = Math.atan2(dir.y, dir.x);            
            player.setRotation(angle);

            const speed=player.speed*tpf;

            const moveDir=p5.createVector(0,0);
            if (p5.keyIsDown(p5.UP_ARROW)) {
                moveDir.y-=1;
            }
             if (p5.keyIsDown(p5.DOWN_ARROW)) {
                moveDir.y+=1;
            }
             if (p5.keyIsDown(p5.LEFT_ARROW)) {
                moveDir.x-=1;
            }
             if (p5.keyIsDown(p5.RIGHT_ARROW)) {
                moveDir.x+=1;
            }

            // normalize
            moveDir.normalize();
            actionMove(player,moveDir,tpf);

            // mouse click
            if(p5.mouseIsPressed){
                // target = 10 unit in front of player
                actionShot(player,dir,tpf);
            }
                  
            
           
        }else{
            const s = player.positionSamples.interpolateWithSample(player.getPos(),player.getRotation(),Date.now(),tpf,player.speed,Math.PI*2);
            if(s){
                player.setPos(s.x,s.y);
                player.setRotation(s.r);
            }
            player.setHealth(player.healthSamples.get());
        }

        // smoke particle
        {
            if (!player.smokeTimeout) player.smokeTimeout = 0;
            if (player.smokeTimeout>0)player.smokeTimeout -= tpf;
            if(
                !player.lastSmokePos||player.lastSmokePos.dist(player.getPos())>2||
                !player.lastSmokeRot||Math.abs(player.lastSmokeRot-player.getRotation())>Math.PI
            
            ){
                if (player.smokeTimeout <= 0) {
                    if (player.lastSmokePos&&player.lastSmokeRot){
                        spawnParticle(new SmokeSprite(p5), player.lastSmokePos);
                    }
                    player.smokeTimeout = 0.2;
                    player.lastSmokePos = player.getPos().copy();
                    player.lastSmokeRot = player.getRotation();

                }
            }
        }

        player.updateBullets((bullet, reachedTarget) => {
            let explode=false;
            if (reachedTarget) {
                explode=true;
            }

            // explode if nearby any player or terrain cell 
            for (const otherPlayer of Object.values(context.players)) {
                if (otherPlayer == player) continue;
                if (otherPlayer.getPos().dist(bullet.pos) < 10) {
                    explode=true;    
                    otherPlayer.damage(10, player.id);                    
                }
            }

            for (let x = 0; x < context.terrainPhysics.length; x++) {
                for (let y = 0; y < context.terrainPhysics[0].length; y++) {
                    const cell = context.terrainPhysics[x][y];
                    if (!cell.attached) continue;
                    if (cell.position.dist(bullet.pos) < cell.size / 2) {
                        explode=true;
                    }
                }
            }

            if(explode){
                console.log("Boom");
                damageTerrain(bullet.pos);
                playSound(new FarawayExplosionSoundSprite(p5), bullet.pos, 1, false);
                playSound(new ExplosionSoundSprite(p5), bullet.pos, 1, false);
                spawnParticle(new ExplosionSprite(p5), bullet.pos, 0, 10);
                return false;
            }           

            if (!bullet.sprite){
                bullet.sprite = spawnParticle(new CannoballSprite(p5), bullet.pos);
            }

            bullet.sprite.setPosition(bullet.pos);           
            return true;
        }, tpf);      
        if (player.currentShotCooldown >0)player.currentShotCooldown -= tpf;
    }

    { // play sounds
        for (const soundSprite of context.soundSprites) {
            await soundSprite.play(tpf, localPlayer ? await localPlayer.getPos() : undefined);
        }
    }

    { // modify terrain
        // p5.push();
        // if (!context.terrainMask) {
        //     context.terrainMask = p5.createFramebuffer({
        //         width: p5.width,
        //         height: p5.height
        //     });
        // }
        // p5.noStroke();
        // context.terrainMask.begin();
        // p5.clear();
        // p5.fill(255, 0, 0, 255);
        // const x = 10;
        // const y = 10;
        // const w = 10;
        // const h = 10
        // p5.rect((- p5.width / 2) + x, (-p5.height / 2) + y, w, h);
        // context.terrainMask.end();


        // p5.pop();

    }

}





/////////////
const sketch = p5i => {
    p5=p5i;
    let lastUpdate=undefined;   
    let lastLogicUpdate=undefined;
    
    p5.setup = async  () => {
        let canvasWidth = p5.windowWidth;
        let canvasHeight = p5.windowHeight;
        p5.createCanvas(canvasWidth, canvasHeight, p5.WEBGL);
        // await init();
        const runUpdater=async ()=>{
            if (!lastLogicUpdate) {
                lastLogicUpdate = Date.now();
            }
            const now = Date.now();
            const tpf = (now - lastLogicUpdate) / 1000.0;
            lastLogicUpdate = now;
            await update(tpf);
            setTimeout(runUpdater,1000/60);
        };
        runUpdater();
    };
    p5.draw = async () => {
        p5.background("#212121");
        if(!lastUpdate) {
            lastUpdate=Date.now();
        }
        const now=Date.now();
        const tpf=(now-lastUpdate)/1000.0;
        lastUpdate=now;
        render(tpf);
    };
    p5.windowResized = async  () => {
        let canvasWidth = p5.windowWidth;
        let canvasHeight = p5.windowHeight;
        p5.resizeCanvas(canvasWidth, canvasHeight);
    };    
    p5.preload = async () => {
        getContext();
    };

    
};
new p5js(sketch);