import SoundSprite from "../SoundSprite.js";

export default class FarawayExplosionSoundSprite extends SoundSprite {
    constructor(p5) {
        super(p5, "./sounds/muffled_explosion.wav");
        this.setVolume(0.1);
        this.setLoop(false);
        this.setRadius(20);

    }

}
