import Sprite from "../Sprite.js";
import GameUtils from "../GameUtils.js";
export default class Smoke extends Sprite{
    constructor(p5){
        super(p5, "./sprites/smoke/smoke.json");
        this.setLoopMode("once");
        this.setFrameRate(0);
        // this.setZIndex(-0.05);
        const maxTime=3;
        this.setOpacity((time)=>{
            // const opacity = Math.abs(Math.sin((time * Math.PI) / maxTime))*0.4;
            const opacity = GameUtils.easeInOut(time, maxTime)*0.4;
            return opacity;
        });
        this.setScale((time)=>{
            return GameUtils.easeInOut(time, maxTime) * 60;
        })
        // this.setScale(40);
        this.setBlendMode(p5.BLEND);
        this.setFrame(Math.floor(Math.random()*15));
        this.setLifeTime(maxTime);
    }


    

}