export default class GameUtils{

    static easeInOut(currentTime, maxTime){
        if (currentTime < 0) {
            return 0;
        }
        if (currentTime > maxTime) {
            return 0;
        }
        return Math.abs(Math.sin((currentTime * Math.PI) / maxTime));
    }
}