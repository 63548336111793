import SoundSprite from "../SoundSprite.js";

export default class ExplosionSoundSprite extends SoundSprite {
    constructor(p5) {
        super(p5, "./sounds/big_explosion.ogg");
        this.setVolume(0.5);
        this.setLoop(false);
        this.setRadius(0.01);
        this.setDistanceFunction(d=>d/6);

    }

}
