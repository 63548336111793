

class PositionSample{
    x=0;
    y=0;
    r=0;
    ts=Date.now();
    constructor( ){
     
    }
    set(x,y,r,ts){
        this.x=x;
        this.y=y;
        this.r=r;
        this.ts=ts;
    }
}
export default class PositionBuffer{
    constructor(bufferLen){
        this.bufferLen=bufferLen;
        this.buffer=[];
    }
    /**
     * 
     * @param {*} x  Sample x position
     * @param {*} y Sample y position
     * @param {*} ts Timestamp at which the sample was taken
     * @returns 
     */
    pushSample(x,y,r,ts){
        if (!ts)return;
        // if ts is < first sample, discard
        if(this.buffer.length>0 && ts<this.buffer[0].ts){
            return;
        }
       

        // insert ts in order
        let i=0;
        while(i<this.buffer.length && this.buffer[i].ts<ts){
            i++;
        }
        this.buffer.splice(i,0,new PositionSample());
        this.buffer[i].set(x,y,r,ts);
        // remove oldest if buffer is too long
        if(this.buffer.length>this.bufferLen){
            this.buffer.shift();
        }
    }

    interpolateWithSample(currentPosition, currentRotation, ts, tpf, interpolationSpeed, rotationSpeed){
        const out={
            x:currentPosition.x,
            y:currentPosition.y,
            r:currentRotation
        }
        const nextSample = this.sampleAt(ts);
        if(nextSample){
            
            const t = interpolationSpeed * tpf;
            const t2=rotationSpeed*tpf;
            // pos
            const warpDistance = 10;
            let dx = nextSample.x - currentPosition.x;
            let dy = nextSample.y - currentPosition.y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            if (distance > warpDistance) {
                console.log("Warp");
                out.x=nextSample.x;
                out.y=nextSample.y;
            }else{           
                out.x= currentPosition.x + t * (nextSample.x - currentPosition.x);
                out.y= currentPosition.y + t * (nextSample.y - currentPosition.y);           
            }

             //
           
            let dr = nextSample.r - currentRotation;
            if (dr > Math.PI) {
                dr -= 2 * Math.PI;
            } else if (dr < -Math.PI) {
                dr += 2 * Math.PI;
            }
            out.r = currentRotation + t2 * dr;

        }



        return out;

    }
  
    sampleAt(ts) {
        if (this.buffer.length === 0) {
            return null;
        }

        if (ts <= this.buffer[0].ts) {
            return { x: this.buffer[0].x, y: this.buffer[0].y, r: this.buffer[0].r };
        }

        for (let i = 0; i < this.buffer.length - 1; i++) {
            
            if (this.buffer[i].ts <= ts && ts <= this.buffer[i + 1].ts) {
                let t = (ts - this.buffer[i].ts) / (this.buffer[i + 1].ts - this.buffer[i].ts);
                return {
                    x: this.buffer[i].x + t * (this.buffer[i + 1].x - this.buffer[i].x),
                    y: this.buffer[i].y + t * (this.buffer[i + 1].y - this.buffer[i].y),
                    r: this.buffer[i].r + t * (this.buffer[i + 1].r - this.buffer[i].r)
                };
            }
        }

        // extrapolate position and angle based on last two samples
        let last = this.buffer[this.buffer.length - 1];
        let secondLast = this.buffer[this.buffer.length - 2];
        if(!secondLast){
            return last;
        }
        let t = (ts - last.ts) / (last.ts - secondLast.ts);
        return {
            x: last.x + t * (last.x - secondLast.x),
            y: last.y + t * (last.y - secondLast.y),
            r: last.r + t * (last.r - secondLast.r)
        };
    }
    // getPositionAt(ts) {
    //     if (this.buffer.length === 0) {
    //         return undefined;
    //     }

    //     if (ts <= this.buffer[0].ts) {
    //         return { x: this.buffer[0].x, y: this.buffer[0].y };
    //     }

    //     for (let i = 0; i < this.buffer.length - 1; i++) {
    //         if (this.buffer[i].ts <= ts && ts <= this.buffer[i + 1].ts) {
    //             let t = (ts - this.buffer[i].ts) / (this.buffer[i + 1].ts - this.buffer[i].ts);
    //             return {
    //                 x: this.buffer[i].x + t * (this.buffer[i + 1].x - this.buffer[i].x),
    //                 y: this.buffer[i].y + t * (this.buffer[i + 1].y - this.buffer[i].y)
    //             };
    //         }
    //     }

    //     // extrapolate position based on last two samples
    //     let last = this.buffer[this.buffer.length - 1];
    //     let secondLast = this.buffer[this.buffer.length - 2];
    //     if(!secondLast){
    //         return last;
    //     }
    //     let t = (ts - last.ts) / (last.ts - secondLast.ts);
    //     return {
    //         x: last.x + t * (last.x - secondLast.x),
    //         y: last.y + t * (last.y - secondLast.y)
    //     };
    // }
}