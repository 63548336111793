import SoundSprite from "../SoundSprite.js";

export default class BackgroundMusicSprite extends SoundSprite{
    constructor(p5){
        super(p5,"./music/the_drums_of_war.ogg");
        this.setVolume(0.02);
        this.setLoop(true);
        this.setPositional(false);


    }

}
