import { Howl, Howler } from 'howler';

export default class Sprite{
    static dataCache={};
    static imgCache={};
    constructor(p5, spritesheet, pos , rot , scale){
        this.p5=p5;
        this.sprite = spritesheet;
        this.currentFrame = 0;
        this.spriteSheet=undefined;
        this.loopMode="noloop";
        this.frameRate=30;
        this.time=0;
        this.pos=pos||p5.createVector(0,0);
        this.rot=rot||0;
        this.scale = scale||p5.createVector(1,1);
        this.listeners={};
        this.zIndex=0;
        this.blendMode=p5.BLEND;
        this.opacity=1;
        this.duration=0;
        this.permanentCache=false;
       
    }



    setPermanentCache(permanentCache){
        this.permanentCache=permanentCache;
    }


    setLifeTime(duration){
        this.duration=duration;
    }
    setOpacity(opacity){
        this.opacity=opacity;
    }

    setBlendMode(blendMode){
        this.blendMode=blendMode;
    }

    setZIndex(zIndex){
        this.zIndex=zIndex;
    }

    setPosition(pos){
        this.pos=pos;
    }

    setRotation(rot){
        this.rot=rot;
    }

    setScale(scale){
        if(typeof scale=="number"){
            scale=this.p5.createVector(scale,scale);
        }
        this.scale=scale;
    }


    addEventListener(event, callback){  
        if(!this.listeners[event]){
            this.listeners[event]=[];
        }
        this.listeners[event].push(callback);
    }

    removeEventListener(event, callback){
        if(this.listeners[event]){
            this.listeners[event]=this.listeners[event].filter(l=>l!=callback);
        }
    }

    _callListeners(event, ...args){
        if(this.listeners[event]){
            for(const l of this.listeners[event]){
                l(...args);
            }
        }
    }


 
    _load(p5){
        if(this.loading) return;        
        this.loading=true;
        (async ()=>{
            const className = this.constructor.name;

            if(!this.data){
                
                if(Sprite.dataCache[className]){
                    const cache=Sprite.dataCache[className].deref();
                    if(cache){
                        this.data=cache;
                    }
                }
                if(!this.data){
                    console.log("Loading",this.sprite,"for",className);
                    const cache= await fetch(this.sprite).then(res => res.json()); 
                    this.data=cache;   
                    Sprite.dataCache[className]=
                    this.permanentCache?{
                        data:cache,
                        deref:()=>cache                   
                    }:new WeakRef(cache);
                }
                
            }
            
            if (!this.spriteSheet){
                if(Sprite.imgCache[className]){
                    const cache=Sprite.imgCache[className].deref();
                    if(cache){
                        this.spriteSheet=cache;
                    }
                }
                const meta = this.data.meta;

                if(!this.spriteSheet){
                    const relImage=meta.image;// relative to sprite parent
                    const spriteDir=this.sprite.split("/").slice(0,-1).join("/");
                    const imagePath=spriteDir+"/"+relImage;
                    console.log("Loading", imagePath, "for", className);
                    this.spriteSheet=await p5.loadImage(imagePath);
                    Sprite.imgCache[className]=
                    this.permanentCache?
                    {
                        data:this.spriteSheet,
                        deref:()=>this.spriteSheet
                    }
                    :new WeakRef(this.spriteSheet);
                }

                if(meta.framerate){
                    this.frameRate=meta.framerate;
                }

        
            }
        })().then(()=>{
            this.loading=false;
            this.loaded=true;
        }).catch(e=>{
            console.error(e);
            this.loading=false;
            this.loaded=false;
        });
  
    }

    _getNumFrames(){
        return this.data.frames.length;
    }

    setFrameRate(frameRate){
        this.frameRate=frameRate;
    }

    setLoopMode(mode){
        this.loopMode=mode;
    }

    setFrame(frameName){
        this._load(this.p5);
        this.currentFrame=frameName;
    }

    _getFrame(){
        if(typeof this.currentFrame=="number"){
            return this.data.frames[this.currentFrame];
        }else{
            return this.data.frames.find(f=>f.filename==this.currentFrame);
        }
        
    }

    play(tpf) {
        this._load(this.p5);
        if (!this.loaded) return;

        this.time += tpf;
       
        
        if (!this.frameRate){
            this.duration-=tpf;
            if(this.duration<=0){
                this._callListeners("end");
            }
            return;
        }
        const isLooping = this.loopMode == "loop";
        const numFrames = this._getNumFrames();
        const frameDuration = 1.0 / this.frameRate;
        let frameIndex;
        if(isLooping){
            frameIndex = Math.floor(this.time / frameDuration) ;
            if(frameIndex>=numFrames){
                frameIndex=frameIndex%numFrames;
             }
        }else{
            frameIndex = Math.min(Math.floor(this.time / frameDuration), numFrames-1);
            if(frameIndex==numFrames-1){
                // this._callListeners("end");
            }
        }
        
        if (frameIndex != this.currentFrame) {
            this.currentFrame = frameIndex;
            this._callListeners("frame", this.currentFrame);
        }
        


    }

    _draw(p5, tpf) {
        this._load(this.p5);
        if(!this.loaded)return;
        this.play(tpf);
        const frame = this._getFrame();
        const pos=typeof this.pos=="function"?this.pos(this.time):this.pos;
        const scale = typeof this.scale == "function" ? this.scale(this.time) : this.scale;
        const rot = typeof this.rot == "function" ? this.rot(this.time) : this.rot;
        const zIndex=typeof this.zIndex=="function"?this.zIndex(this.time):this.zIndex;
        const blendMode=typeof this.blendMode=="function"?this.blendMode(this.time):this.blendMode;
        const alpha = typeof this.opacity == "function" ? this.opacity(this.time) : this.opacity;
        
        p5.push();
        p5.translate(pos.x, pos.y);
        p5.rotate(rot);
        p5.imageMode(p5.CENTER);
        p5.noStroke();    
        let u0 = frame.frame.x;
        let v0 = frame.frame.y;
        let u1 = u0 + frame.frame.w;
        let v1 = v0 + frame.frame.h;

        const uvInPixels = true;
        if (!uvInPixels) {
            u0 /= this.spriteSheet.width;
            v0 /= this.spriteSheet.height;
            u1 /= this.spriteSheet.width;
            v1 /= this.spriteSheet.height;
        }
        const hfx = (typeof scale=="number"?scale:scale.x) / 2
        const hfy = (typeof scale == "number" ? scale : scale.y) / 2;

        p5.tint(255, 255,255,255 * alpha);
        p5.blendMode(blendMode);
        p5.texture(this.spriteSheet); // Use texture() instead of p5.texture()
        p5.beginShape();
        p5.vertex(-hfx, -hfy, zIndex, u0, v0);
        p5.vertex(hfx, -hfy, zIndex, u1, v0);
        p5.vertex(hfx, hfy, zIndex, u1, v1);
        p5.vertex(-hfx, hfy, zIndex, u0, v1);
        p5.endShape(p5.CLOSE);
        // p5.rect(-hfx, -hfy, this.scale.x, this.scale.y, uvx, uvy, uvw, uvh);

        // console.log("Draw", uvx, uvy, uvw, uvh);
        // 
        p5.pop();

    }

    draw(tpf){
        this._load(this.p5);
        return this._draw(this.p5,tpf);

    }
    
}