import Sprite from "../Sprite.js";

export default class CannoballSprite extends Sprite {
    constructor(p5) {
        super(p5, "./sprites/explosion/explosion.json");
        this.setLoopMode("once");
        this.setScale(4);
    }




}