import Utils from "./Utils.js";
export default class Connection {
    constructor(topic){
        this.topic = topic;
        this.listeners = [];   
        this.sysListeners = [];
        this.peerListeners = [];
        this.orphanListeners = [];
        this.publicTopic = Utils.sha256(this.topic);
        this.peers = {};
        // this.dynamicOwner=true;
        // this.ownerCandidates=[];
        // this.initOwnerNegotiationTimestamp=0;

        this.peerTimeout = 4000;
        this.timeoutMargin = 2000
        this.managmentTimeout = 2000;

    }

    _createEvent(peerId, timestamp, payload){
        if (timestamp> Date.now()+1) {
            alert("Invalid timestamp");
            throw new Error("Invalid timestamp");
        }
        return {
            peerId,
            timestamp,
            payload, 
            fromOwner: peerId === this.getTopicOwnerId()
        };
    }

    // initOwnerNegotiation(){
    //     this.ownerNegotiationStarted=true;
    // }

    // addOwnerCandidate(peerId, timestamp, timeRange=10000, validity=4000){        
    //     let candidate=this.ownerCandidates.find(c=>c.peerId===peerId);
    //     if(!candidate)candidate={};
    //     else return; // only once
    //     candidate.peerId=peerId;
    //     candidate.timestamp=timestamp;
    //     candidate.readyAfter=timestamp+timeRange;
    //     candidate.validity = candidate.readyAfter+validity;
    //     console.log("Peer", peerId, "is claiming ownership from",candidate.readyAfter, "valid for ",validity,"ms");
    //     this.ownerCandidates.push(candidate);
    //     // remove invalid candidates
    //     this.ownerCandidates = this.ownerCandidates.filter(c => c.validity > Date.now());
    // }

    // async commitOwnerCandidate(){
    //     // remove invalid candidates
    //     this.ownerCandidates=this.ownerCandidates.filter(c=>c.validity>Date.now());
    //     // pick only candidates that are ready
    //     let candidates=this.ownerCandidates.filter(c=>c.readyAfter<Date.now());
    //     // sort by timestamp
    //     candidates.sort((a,b)=>a.timestamp-b.timestamp);
    //     // filter all candidates with timestamp==first.timestamp
    //     candidates=candidates.filter(c=>c.timestamp===candidates[0].timestamp);
    //     // sort by peerId string in utf8
    //     candidates.sort((a,b)=>a.peerId.localeCompare(b.peerId));
    //     // check if any
    //     if (candidates.length===0)return false;
    //     // pick first
    //     const newOwner=candidates[0].peerId;
    //     const newOwnerPeer = await this.getPeer(newOwner);
    //     if (!newOwnerPeer){
    //         console.log("Invalid peer is trying to claim ownership, skipping");
    //         return false;
    //     }

    //     // set new owner
    //     this.setTopicOwner(newOwner,true);
    //     this.ownerNegotiationStarted=false;  
    //     this.onPeerUpdate(newOwnerPeer);
        
              
    //     return true;
    // }

    // isNegotiatingOwner(){
    //     return !!this.ownerNegotiationStarted;
    // }



   

    getPublicTopic() {
        return this.publicTopic;
    }


    setTopicOwner(peerId, dynamic=false) {
        // if (!peerId){
        //     this.dynamicOwner=true;
        //     return;
        // }else{
            this.owner = peerId;
        //     this.dynamicOwner = dynamic;
        // }
    }

    getTopicOwnerId() {        
        return this.owner;      
    }


   

    isDynamicOwner(){
        return this.dynamicOwner;
    }

    async getPeers(filterBad=true) {
        const peers=[];
        for(const peer of Object.values(this.peers)){
            if(filterBad){
                if(await this.isKicked(peer))continue;
                if(await this.isTimedOut(peer))continue;
                // if(!await this.isConnected(peer))continue;
            }
            peers.push(peer);
        }
        return peers;
    }

    async waitForPeers(nPeers, waitLocal = true) {
        const local=await this.getLocalPeerId();
        return new Promise((resolve, reject) => {
            const check = async () => {
                const peers = Object.values(await this.getPeers());
                if ((!waitLocal || peers.filter(p => p.id==local)) && peers.length >= nPeers) {
                    resolve(peers);
                } else {
                    setTimeout(check, 1000);
                }
            }
            check();
        });
    }

    async isKicked(peer){
        peer=await this.getPeer(peer);
        if (!peer) return true;
        if (!peer.kickTime) return false;
        if (peer.kickTime < Date.now()) return false;
        return true;
    }

    async isTimedOut(peer) {
        peer = await this.getPeer(peer);
        if (!peer){
            return true;
        }
        const timeout=peer.timeout || 0;
        const lastSeen=peer.lastSeen || 0;
        if(lastSeen+timeout<Date.now()){
            return true;
        }
        return false;
    }

    // async isConnected(peer){
    //     peer = await this.getPeer(peer);
    //     if (!peer) return false;
    //     return peer.connected;
    // }

    async _isGarbage(peer) {
        if (!peer) return true;
        peer = await this.getPeer(peer);
        if (peer.kickTime && peer.kickTime > Date.now()) return false;
        if (peer.timeout && peer.lastSeen + peer.timeout > Date.now()) return false;
        return true;
    }

    async kickPeer(peerId, time = 1000 * 60) {
        let peer = await this.getPeer(peerId);
        if (!peer) {
            if (typeof peerId === 'object') {
                return;
            }else{
                peer=this.peers[peerId] = {
                    id: peerId
                }
            }
        }
        peer.kickTime = Date.now() + time;
        return true;
    }

    async removePeer(peer){
        if(typeof peer != "string")peer=peer.id;
        delete this.peers[peer];
    }

    async connectToPeers(peerIds, lock = true) {
        for (const peerId of peerIds) {
            if (peerId === await this.getLocalPeerId()) continue;
            const peer = this.peers[peerId];
            if (!peer) {
                this.peers[peerId] = {
                    id: peerId,
                    timeout: this.peerTimeout,
                    lastSeen: Date.now(),
                    // connected: true,
                }
            }
        }
        return this.connect();
    }

   
    async onEvent(peer,event){
        event.peer=peer;
        for(let listener of this.listeners){
            listener(event);
        }
    }

    async onOrphanEvent(event){
        for (let listener of this.orphanListeners){
            listener(event);
        }
    }

    async onSysEvent(event){
        for (let listener of this.sysListeners){
            listener(event);
        }
    }

    async addEventListener(listener){
        this.listeners.push(listener);
    }

    async addOrphanEventListener(listener){
        this.orphanListeners.push(listener);
    }

    async addSystemEventListener(listener) {
        this.sysListeners.push(listener);
    }

    async addPeerEventListener(listener) {
        this.peerListeners.push(listener);
    }

    async onPeerUpdate(peer , gc){
        for(let listener of this.peerListeners){
            listener(peer, gc);
        }
    }

    

    async getPeer(peerId, refreshTime) {
        if (typeof peerId === 'object') peerId = peerId.id;
        let peer = this.peers[peerId];
        if(!peer){
            peer={
                id: peerId,
                lastSeen: 0,
                timeout: this.peerTimeout + this.timeoutMargin ,
            }
            this.peers[peerId]=peer;
        }
        if(refreshTime){
            if(refreshTime<=Date.now()+1){
                peer.lastSeen = refreshTime;
            }else{
                alert("Invalid refresh time");
            }
        }

        // if (filterBad) {
        //     if (await this.isKicked(peer)) return undefined;
        //     if (await this.isTimedOut(peer)) return undefined;
        //     if (!await this.isConnected(peer)) return undefined;
        // }
        return peer;
    }

    async getLocalPeer(){
        return this.getPeer(await this.getLocalPeerId(),Date.now());
    }

    // Abstract methods
    async getLocalPeerId() {
        throw new Error("Not implemented");
    }

    async start(makeDiscoverable = true) {
        this.stopped = false;
        // mng/dispose routine
        const managePeers = async () => {


            const peers = await this.getPeers(false);

            // handle kicks and timeout and dispose of garbage
            for (const peer of peers) {
                try {
                    if (!peer.timedout && await this.isTimedOut(peer)) {
                        console.log("Peer", peer.id, "timed out");
                        peer.timedout = true;
                        this.onPeerUpdate(peer);
                    }
                    if (!peer.kicked && await this.isKicked(peer)) { //timed out
                        console.log("Peer", peer.id, "kicked out");
                        peer.kicked = true;
                        this.onPeerUpdate(peer);
                    }
                } catch (e) {
                    console.log(e);
                }

                // dispose garbage
                // if(!this.arePeersLocked){
                try {

                    if (await this._isGarbage(peer)) {
                        console.log("Peer", peer.id, "is garbage");
                        await this.removePeer(peer.id);
                        peer.gced = true;
                        this.onPeerUpdate(peer);
                    }
                } catch (e) {
                    console.log(e);
                }
                // }


            }


            // check if owner is live
            // const ownerPeer = await this.getPeer(await this.getTopicOwnerId());
            // // init ownership negotiation if owner is dead
            // if(this.isDynamicOwner()&& !ownerPeer&&!this.isNegotiatingOwner()){
            //     this.initOwnerNegotiation();
            // }
            // // submit ownership claim
            // if(this.isNegotiatingOwner()){
            //     this.sendSysEvent({
            //         claimOwner:true
            //     }, managmentTimeout+timeoutMargin);

            //     // check if there is a replacement
            //    await this.commitOwnerCandidate();
            // }


            if (this.stopped) return;
            this.peerDisposalTimer = setTimeout(managePeers, this.managmentTimeout);
        }
        managePeers();


        // ping routine
        if (makeDiscoverable) {
            const ping = async () => {
                try {
                    await this.sendPeerEvent(
                        {
                        }
                        , this.peerTimeout + this.timeoutMargin);
                } catch (e) {
                    console.log("Ping event",e);
                }
                if (this.stopped) return;
                this.discoverTimeout = setTimeout(() => ping(), this.peerTimeout);
            }
            ping();
        }
    }

    async stop() {
        
        this.stopped = true;
        if (this.discoverTimeout) {
            clearTimeout(this.discoverTimeout);
            this.discoverTimeout = undefined;
        }     


        if (this.peerDisposalTimer) {
            clearTimeout(this.peerDisposalTimer);
            this.peerDisposalTimer = undefined;
        }
    }


    async connect(lock = true) {
        throw new Error("Not implemented");
    }

    async sendPeerEvent(payload, expiration = 0) {
        throw new Error("Not implemented");
    }
    async sendSysEvent(payload, expiration = 0) {
        throw new Error("Not implemented");
    }
    async sendEvent(payload, expiration = 0) {
        throw new Error("Not implemented");
    }

}