import Connection from "../Connection.js";
import { Relay, finalizeEvent, generateSecretKey, getPublicKey, verifyEvent } from 'nostr-tools'
import { SimplePool } from 'nostr-tools'
import Utils from "../Utils.js";

export default class NostrConnection extends Connection {
    constructor(
        topic,
        useIntegration = true,
        proto="lk01",
        relays,
        peerKind = 64176,
        sysKind = 64175,
        persistentDataKind = 64174,
        messageKind = 64173,

    
    ){
        super(topic);
        if(!relays){
            relays=[
                // "wss://nostrrelay.com"
                // "wss://relay.nostrss.re",
                "wss://nostr.rblb.it:7777"
                
            ]
        }
        this.proto=proto;
        this.relays = relays;
        this.pool = new SimplePool()
        this.peerKind = peerKind
        this.sysKind = sysKind
        this.messageKind = messageKind
        this.useIntegration=useIntegration;
        this.persistentDataKind = persistentDataKind;


 
    }

    async getLocalPeerId() {
        return await this._getPublicKey();
    }


    async _getPrivKey(){
        if(this.sk){
            return this.sk;
        }
        if (this.useIntegration && window.nostr&&window.nostr.getPublicKey){
            throw "Signing is delegated."
        }else{
            this.sk=generateSecretKey();
            return this.sk;
        }
    }

    async _getPublicKey(){
        if(this.pk){
            return this.pk;
        }
        if (this.useIntegration&&window.nostr&&window.nostr.getPublicKey){
            return window.nostr.getPublicKey();
        }else{
            const sk=await this._getPrivKey();
            this.pk=getPublicKey(sk);
            return this.pk;
        }
    }


    async _toEvent(payload, kind, expiration=0){
        payload.proto=this.proto;
        payload = await Utils.encryptStr(JSON.stringify(payload), this.topic);

        const tags=[];
        if(expiration){
            let exp = (Date.now() + expiration);
            let d = Date.now() - exp;
            if(d<2000) exp+=2000-d;
            exp = Math.ceil(exp/1000);
            tags.push(["expiration",""+exp]);
        }
        tags.push(["t",await this.getPublicTopic()]);
        
        const event = {
            kind: kind,
            created_at: Math.floor(Date.now() / 1000),
            tags: tags,
            content: payload
        }
        let signedEvent;
        if(this.useIntegration&&window.nostr&&window.nostr.signEvent){
            signedEvent= await window.nostr.signEvent(event);
        }else{
            signedEvent = finalizeEvent(event, await this._getPrivKey())
        }
        const isValid=verifyEvent(signedEvent);
        if(!isValid)throw new Error("Invalid event");
        return signedEvent;
    }

    async _any(promises) {
        try {
            return await Promise.any(promises);
        } catch (e) {
            if (e instanceof AggregateError) {
                for (const err of e.errors) {
                    console.error("Failed promise", err);
                }
            }
            throw e;
        }
    }

    async _fromEvent(event){
        const isValid = verifyEvent(event);
        if (!isValid) throw new Error("Invalid event");
        const peerId=event.pubkey;
        const payload=JSON.parse(await Utils.decryptStr(event.content, this.topic));
        if(payload.proto!==this.proto)throw new Error("Invalid protocol");
        return this._createEvent(peerId, event.created_at*1000, payload);
    }

    async sendPeerEvent(payload,  expiration = 0) {
        if(!payload)payload={};
        payload.pk=await this._getPublicKey();
        // console.log("Send peer event", payload);
        const event = await this._toEvent(payload, this.peerKind, expiration);
        
        return this._any(this.pool.publish(this.relays, event))
    }

    async sendSysEvent(payload, expiration = 0) {
        // console.log("Send system event", payload);
        const event = await this._toEvent(payload, this.sysKind, expiration);
        return this._any(this.pool.publish(this.relays, event))
    }

    async sendEvent(payload, expiration=0){
        // console.log("Send event", payload);
        const event = await  this._toEvent(payload, this.messageKind, expiration);
        return this._any(this.pool.publish(this.relays, event))
    }

    async sendDataEvent(payload, expiration=0){
        const event = await this._toEvent(payload, this.persistentDataKind, expiration);
        return this._any(this.pool.publish(this.relays, event))
    }


    async _onEvent(kind, event){
        if (this.stopped){
            console.warn("Is stopped. Ignore event")
            return;
        } 
        if (kind == this.peerKind) { // peer update event
            const peerId = event.peerId;
            const payload = event.payload;
            const timestamp = event.timestamp;

            // invalid event
            if (payload.pk != peerId) throw new Error("Corrupted event " + peerId + " != " + payload.pk);

            const peer = await this.getPeer(peerId, timestamp);
            // if (!peer) {
            //     // if (this.arePeersLocked) { // peer unavailable, retry later
            //     //     console.log("New connection but peers are locked");
            //     //     this._retryLater(kind,event, 10, 1000); // retry later
            //     //     return;
            //     // } else { // add peer if unlocked
            //         peer = this.peers[peerId] = {
            //             id: peerId,
            //             connected:true,
            //             lastSeen:timestamp,
            //             timeout:timeout
            //         }
            //     // }
            // }

            if (await this.isKicked(peer)) { // seemingly kicked peer, retry later
                console.log("Peer is kicked");
                // this._retryLater(kind,event, 4, 1000);
                return;
            }

            // peer.lastSeen = timestamp;
            // peer.timeout = timeout;

            this.onPeerUpdate(peer);
            // console.log("Peers (local:", await this._getPublicKey(), ")");
            // console.log(JSON.stringify(this.peers, null, 2));
            // console.log("---");
       
        } else if (kind == this.sysKind){ // system event
                // if (event.payload.claimOwner) {
                //     // register owner candidate, doesn't matter if 
                //     // it is not negotiating yet, it might happen later
                //     this.addOwnerCandidate(event.peerId, event.timestamp);
                //     return;
                // } else 
                if (!event.fromOwner) {
                    console.log("Someone who is not the owner is trying to system the topic", event);                 
                    // this._retryLater(kind,event,10,1000); // seemingly invalid event, retry later
                    return;
                }
                // valid owner, not a claim event
                this.onSysEvent(event);
        } else if (kind == this.messageKind||kind==this.persistentDataKind) {         
            
            const peer = await this.getPeer(event.peerId, event.timestamp);
            if (!peer ) { 
                console.log("Orphan event", event);
                this.onOrphanEvent(event);
            }else{
                this.onEvent(peer, event);
            }
            
           
        }

    }
 

    async _subscribe(kind, limit){
        const self=this;
        if (!this.eventBacklog) {
            this.eventBacklog = {};
        }
        if (!this.eventBacklog[kind]) {
            this.eventBacklog[kind] = {
                clear:false,
                events:[]
            };
        }
        const backlog=this.eventBacklog[kind];
        return  this.pool.subscribeMany(
            this.relays,
            [
                {
                    kinds: [kind],
                    "#t": [await this.getPublicTopic()],
                    limit: limit
                },
            ],
            {
                maxWait: 60*1000*60,
                async onevent(event) {
                    const kind = event.kind;
                    event = await self._fromEvent(event);
                    if (!backlog.clear) {
                        event.isBacklog = true;
                        backlog.events.push([kind,event]);
                    }else{
                        self._onEvent(kind, event);
                    }
                },
                async oneose(){
                    //sort from older to newer
                    while(true){
                        backlog.events.sort((a,b)=>a[1].timestamp-b[1].timestamp);
                        const eventsToProcess=backlog.events;
                        backlog.events=[];
                        for (const [kind, event] of eventsToProcess){
                            await self._onEvent(kind, event);
                        }
                        if(backlog.events.length==0){
                            backlog.clear=true;
                            break;
                        }
                    }
                

                }
            }
        );
    }

    async start(makeDiscoverable=true){
        this.stopped = false;

        // const processEvents = async () => {
        //     try {
        //         await this._processEventQueue();
        //     } catch (e) {
        //         console.log(e);
        //     }
        //     if (this.stopped) return;
        //     this.eventProcessTimer=setTimeout(processEvents, 10);
        // };
        // processEvents();


        console.log("Subscrive to relays",this.relays,"as",await this._getPublicKey());     
        const self=this;


        // peer managment
        this.peerChannel=await this._subscribe(this.peerKind);
       
        // sys channel     
        this.systemChannel=await this._subscribe(this.sysKind);   
       
        super.start(makeDiscoverable);
    }

    async stop() {
        this.stopped=true;
        super.stop();

        if(this.systemChannel){
            this.systemChannel.close();
            this.systemChannel=undefined;
        }

        if (this.peerChannel) {
            this.peerChannel.close();
            this.peerChannel = undefined;
        }

        if (this.messageChannel) {
            this.messageChannel.close();
            this.messageChannel = undefined;
        }

      

     
        return this.pool.close();
    }
   
 

    async connect(lockPeers=true){
        // message channel
        this.messageChannel = await this._subscribe(this.messageKind, 100);
        
        // data channel
        this.persistentDataChannel = await this._subscribe(this.persistentDataKind);
    }
    

 



}
