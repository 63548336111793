import * as secp256k1 from '@noble/secp256k1';
import { sha256 } from '@noble/hashes/sha256';
import * as utils from '@noble/hashes/utils';
import { xchacha20poly1305 } from '@noble/ciphers/chacha';
import * as cipherUtils from '@noble/ciphers/utils';


export default class Utils {

    static newKeyPair(){
        const priv = secp256k1.utils.randomPrivateKey();
        const pub = secp256k1.getPublicKey(priv);
        return {
            priv: priv.toString('hex'),
            pub: pub.toString('hex')
        }
    }
 
    static  getKeyPair(priv){   
        const pub = secp256k1.getPublicKey(Buffer.from(priv,'hex'));
        return {
            priv: priv,
            pub: pub.toString('hex')
        }
    }

    static toHex(buffer){
        return utils.bytesToHex(buffer);
    }

    static stringToBytes(str){
        return utils.utf8ToBytes(str);
    }

    static randomBytes(n) {
        return utils.randomBytes(n);
    }

    static sha256(buffer) {
        return utils.bytesToHex(sha256(buffer));
    }


    static concatBuffers(...buffers) {
        const totalLength = buffers.reduce((acc, b) => acc + b.length, 0);
        const result = new Uint8Array(totalLength);
        let offset = 0;
        for (const b of buffers) {
            result.set(b, offset);
            offset += b.length;
        }
        return result;
    }


    static async encryptStr(dataString, passphrase){
        const key = sha256(passphrase);
        const nonce = utils.randomBytes(24);
        const chacha = xchacha20poly1305(key, nonce);
        const data = cipherUtils.utf8ToBytes(dataString);
        const ciphertext = chacha.encrypt(data);
        return cipherUtils.bytesToHex(nonce) + cipherUtils.bytesToHex(ciphertext);
    }
    
    static async decryptStr(dataString, passphrase){
        const key = sha256(passphrase);
        const nonce = cipherUtils.hexToBytes(dataString.slice(0,48));
        const chacha = xchacha20poly1305(key, nonce);
        const ciphertext = cipherUtils.hexToBytes(dataString.slice(48));
        const plaintext = chacha.decrypt(ciphertext);
        return cipherUtils.bytesToUtf8(plaintext);
    }
}